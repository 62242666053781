<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>Einstellungen</h1>
      <div class="meta">

      </div>
    </header>



  </div>
</template>

<script>
export default {
  name: 'admin_settings',
  data () {
    return {

    }
  },
  methods: {
    async get_data() {

    },
  },
  mounted () {
    this.get_data();
  }
}
</script>
